@use '~@wienenergiegithub/ui-next/src/common/assets/styles/variables' as *;

.content {
  width: 100%;
  min-height: rem(562px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subtitle {
  text-align: center;
  margin-bottom: space(sm);
  max-width: 480px;
}

.form {
  margin-top: space();
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    width: 550px;
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: space(xxs);

  + div[role='alert'] {
    margin-bottom: space(xs);
  }
}

.radioGroupContainer {
  display: flex;
  gap: space(sm);
}

.formLabel {
  font-size: font-size(md);
  font-weight: font-weight(semi);
  color: color(secondary);
}

.legalText {
  font-size: font-size();
  padding-top: rem(3px);

  a {
    font-weight: font-weight(regular);
  }
}

.nameWrapper {
  margin-bottom: space(xs);

  @include media-breakpoint-up(md) {
    display: flex;
    width: 100%;

    div:first-child {
      margin-right: space(sm);
    }
  }
}

.emailInput {
  margin-bottom: space(md);
}

.submit {
  margin-top: space();
  align-self: center;
}

.contentIcon {
  margin-bottom: space();
  color: color(primary);
  width: space(xxxl) !important;
  height: space(xxxl) !important;
}
